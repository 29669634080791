import React from 'react';
import { getAuth, signOut } from "firebase/auth";

function NavBar({ user, onSignInClick }) {
  const auth = getAuth();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("Signed out successfully");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <nav className="bg-blue-500 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white font-bold text-xl">Survey Designer</div>
        <div>
          {user ? (
            <div className="flex items-center">
              <span className="text-white mr-4">
                {user.isAnonymous ? "Anonymous User" : user.displayName}
              </span>
              {user.isAnonymous ? (
                <button
                  onClick={onSignInClick}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Sign In
                </button>
              ) : (
                <button
                  onClick={handleSignOut}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Sign Out
                </button>
              )}
            </div>
          ) : (
            <span className="text-white">Loading...</span>
          )}
        </div>
      </div>
    </nav>
  );
}

export default NavBar;