import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSurveyResponses, getSurvey } from '../utils/serverComm';
import { BarChart, Bar, LineChart, Line, PieChart, Pie, Cell, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'; // For filtering
import { getAuth } from 'firebase/auth';

const chartColors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1'];

const SurveyAnalytics = () => {
  const { surveyId } = useParams();
  const [responses, setResponses] = useState([]);
  const [survey, setSurvey] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [chartType, setChartType] = useState('BarChart');
  const auth = getAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const surveyData = await getSurvey(surveyId, idToken);
        setSurvey(surveyData.survey);
        const responsesData = await getSurveyResponses(surveyId, idToken);
        setResponses(responsesData.responses);
        if (surveyData.survey.questions.length > 0) {
          setSelectedQuestionId(surveyData.survey.questions[0].id);
        }
      } catch (error) {
        console.error('Error fetching analytics data:', error);
      }
    };

    fetchData();
  }, [surveyId, auth]);

  if (!survey) {
    return <div>Loading survey analytics...</div>;
  }

  const question = survey.questions.find((q) => q.id === selectedQuestionId);

  if (!question) {
    return <div>No question selected</div>;
  }

  const data = responses.map((resp) => ({
    answer: resp.answers[selectedQuestionId],
  }));

  const renderChart = () => {
    const answerCounts = {};
    data.forEach(({ answer }) => {
      const key = answer !== undefined && answer !== null ? answer.toString() : 'No Answer';
      answerCounts[key] = (answerCounts[key] || 0) + 1;
    });

    const chartData = Object.keys(answerCounts).map((key) => ({
      name: key,
      value: answerCounts[key],
    }));

    switch (chartType) {
      case 'BarChart':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData}>
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        );
      case 'LineChart':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={chartData}>
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        );
      case 'PieChart':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                outerRadius={150}
                fill="#8884d8"
                label
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        );
      default:
        return null;
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Survey Analytics: {survey.title}</h2>
      <div className="flex space-x-4 mb-6">
        <FormControl variant="outlined">
          <InputLabel id="question-select-label">Question</InputLabel>
          <Select
            labelId="question-select-label"
            value={selectedQuestionId}
            onChange={(e) => setSelectedQuestionId(e.target.value)}
            label="Question"
          >
            {survey.questions.map((q) => (
              <MenuItem key={q.id} value={q.id}>
                {q.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="chart-type-select-label">Chart Type</InputLabel>
          <Select
            labelId="chart-type-select-label"
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            label="Chart Type"
          >
            <MenuItem value="BarChart">Bar Chart</MenuItem>
            <MenuItem value="LineChart">Line Chart</MenuItem>
            <MenuItem value="PieChart">Pie Chart</MenuItem>
          </Select>
        </FormControl>
      </div>
      {renderChart()}
    </div>
  );
};

export default SurveyAnalytics;