import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import SurveyDesigner from './components/SurveyDesigner';
import SurveyList from './components/SurveyList';
import NavBar from './components/NavBar';
import SignIn from './components/SignIn';
import { CircularProgress } from '@mui/material';
import SurveyViewer from './components/SurveyViewer';
import SurveyAnalytics from './components/SurveyAnalytics'; // Import the new component

const firebaseConfig = {
  apiKey: "AIzaSyCvNhW8CXd_i6lea-SaKSKdyVXFMIM1tQ8",
  authDomain: "surveymurvey-98026.firebaseapp.com",
  projectId: "surveymurvey-98026",
  storageBucket: "surveymurvey-98026.appspot.com",
  messagingSenderId: "662223204318",
  appId: "1:662223204318:web:e1304029fba81650c6374f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function App() {
  const [user, setUser] = useState(null);
  const [showSignIn, setShowSignIn] = useState(false);
  const [loading, setLoading] = useState(true); // Add this state
  const signInRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setShowSignIn(false);
        console.log("User arrived:", user);
      } else {
        signInAnonymously(auth)
          .then(() => {
            console.log("Signed in anonymously");
          })
          .catch((error) => console.error("Error signing in anonymously:", error));
      }
      setLoading(false); // Set loading to false when auth state is determined
    });

    return () => unsubscribe();
  }, []);

  const handleSignInClick = () => {
    setShowSignIn(true);
  };

  const handleClickAway = (event) => {
    if (signInRef.current && !signInRef.current.contains(event.target)) {
      setShowSignIn(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Router>
      <div className="App bg-gray-100 min-h-screen">
        <NavBar user={user} onSignInClick={handleSignInClick} />
        <Routes>
          <Route path="/" element={<SurveyList user={user} />} />
          <Route 
            path="/survey/:surveyId" 
            element={
              user ? <SurveyDesigner /> : <Navigate to="/" replace />
            } 
          />
          <Route path="/s/:surveyId" element={<SurveyViewer />} />
          <Route
            path="/survey/:surveyId/analytics"
            element={user ? <SurveyAnalytics /> : <Navigate to="/" replace />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        {showSignIn && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            onClick={handleClickAway}
          >
            <div ref={signInRef} className="bg-white p-8 rounded-lg">
              <SignIn />
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;