import { getAuth, signInAnonymously } from "firebase/auth";

//const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';
const API_BASE_URL = 'https://surveymurvey.uc.r.appspot.com';

// Helper function to get the auth token
const getAuthToken = async () => {
    const auth = getAuth();
    let user = auth.currentUser;
    
    if (!user) {
      // Sign in anonymously if no user is signed in
      const userCredential = await signInAnonymously(auth);
      user = userCredential.user;
    }
    
    return user.getIdToken();
};

// Generic API call function
const apiCall = async (method, endpoint, data = null, idToken = null) => {
    const token = idToken || await getAuthToken();
    const url = `${API_BASE_URL}${endpoint}`;
    
    const options = {
      method,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      if (response.status === 403) {
        throw new Error('Unauthorized access');
      }
      throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
};

// Generic GET method
export const get = (endpoint) => apiCall('GET', endpoint);

// Generic POST method
export const post = (endpoint, data) => apiCall('POST', endpoint, data);

// Create Survey
export const createSurvey = () => post('/api/createSurvey');

// Save Survey
export const saveSurvey = (surveyId, questions, surveyTitle) => {
  console.log('Client: saveSurvey called with:', { surveyId, questions, surveyTitle });
  return post('/api/saveSurvey', { surveyId, questions, surveyTitle });
};

// Get Surveys
export const getSurveys = () => get('/api/getSurveys');

// Get Single Survey
export const getSurvey = (surveyId) => {
  return get(`/api/getSurvey/${surveyId}`);
};

// Submit Survey Response
export const submitSurveyResponse = (surveyId, answers) => {
  return post('/api/submitResponse', { surveyId, answers });
};

// Get Survey Responses
export const getSurveyResponses = (surveyId, idToken) => {
  return apiCall('GET', `/api/getSurveyResponses/${surveyId}`, null, idToken);
};