import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import QuestionDesigner from './QuestionDesigner';
import SurveyPreview from './SurveyPreview';
import { saveSurvey, getSurvey } from '../utils/serverComm'; // Import getSurvey
import { Save, BarChart2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

const SurveyDesigner = () => {
  const { surveyId } = useParams();
  const [saveStatus, setSaveStatus] = useState('idle');
  const [surveyTitle, setSurveyTitle] = useState('');
  const [questions, setQuestions] = useState([]); // Add this line
  const previewRef = useRef(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  // Ref to store the previous surveyId
  const previousSurveyIdRef = useRef();

  const fetchSurveyDetails = useCallback(async (id) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await getSurvey(id, idToken);
      if (response && response.survey) {
        setSurveyTitle(response.survey.title || '');
        setQuestions(response.survey.questions || []);
      } else {
        setError('Survey not found or you do not have permission to access it');
        navigate('/');
      }
    } catch (error) {
      console.error('Error fetching survey details:', error);
      setError('Error fetching survey details. Please try again.');
      navigate('/');
    }
  }, [navigate, auth]); // Remove setQuestions and setSurveyTitle from dependencies

  useEffect(() => {
    // Check if surveyId has changed and is not undefined/null
    if (surveyId && surveyId !== previousSurveyIdRef.current) {
      fetchSurveyDetails(surveyId);
    }
    // Update the ref with the current surveyId
    previousSurveyIdRef.current = surveyId;
  }, [surveyId, fetchSurveyDetails]); // Include fetchSurveyDetails in dependencies

  const addQuestion = (question) => {
    setQuestions([...questions, question]);
  };

  useEffect(() => {
    if (previewRef.current) {
      previewRef.current.scrollTop = previewRef.current.scrollHeight;
    }
  }, [questions]);

  const handleSaveSurvey = async () => {
    if (!surveyId) {
      console.error('No survey ID. Please create a new survey first.');
      return;
    }
    if (!surveyTitle || surveyTitle.trim() === '') {
      alert('Please enter a survey title before saving.');
      return;
    }
    console.log('Saving survey with title:', surveyTitle);
    setSaveStatus('saving');
    try {
      const response = await saveSurvey(surveyId, questions, surveyTitle);
      console.log('Survey saved successfully:', response);

      // Introduce a delay before setting the status to 'saved'
      setTimeout(() => {
        setSaveStatus('saved');
        // Reset back to 'idle' after a delay
        setTimeout(() => setSaveStatus('idle'), 2000);
      }, 1000);
    } catch (error) {
      console.error('Error saving survey:', error);
      setSaveStatus('idle');
    }
  };

  const handleAnalyzeResponses = () => {
    navigate(`/survey/${surveyId}/analytics`);
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden h-screen flex flex-col">
      <h2 className="text-2xl font-bold mb-6 bg-red-700 text-white py-3 px-6">
        Survey Designer
      </h2>
      <div className="flex flex-grow overflow-hidden">
        <div className="w-1/2 p-6 border-r border-gray-200 overflow-y-auto bg-gray-100">
          {/* Survey Title Input */}
          <div className="mb-6">
            <input
              type="text"
              value={surveyTitle}
              onChange={(e) => setSurveyTitle(e.target.value)}
              className="w-full text-3xl font-bold p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          {/* Buttons */}
          <div className="mb-4 flex space-x-2">
            <button
              onClick={handleSaveSurvey}
              disabled={saveStatus === 'saving'}
              className={`${
                saveStatus === 'saving' ? 'bg-yellow-500' : 'bg-green-500 hover:bg-green-600'
              } text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition-all`}
            >
              {saveStatus === 'saving' ? (
                <>
                  <svg
                    className="inline mr-2 w-5 h-5 text-white animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v4l-4 4 4 4v4a8 8 0 01-8-8z"
                    ></path>
                  </svg>
                  Saving...
                </>
              ) : saveStatus === 'saved' ? (
                <>
                  <Save size={20} className="inline mr-2" />
                  Saved
                </>
              ) : (
                <>
                  <Save size={20} className="inline mr-2" />
                  Save Survey
                </>
              )}
            </button>
            <button
              onClick={handleAnalyzeResponses}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              <BarChart2 size={20} className="inline mr-2" />
              Analyze Responses
            </button>
          </div>
          <h3 className="text-xl font-semibold mb-4">Question Designer</h3>
          <div className="sticky top-0 bg-gray-100 pb-6">
            <QuestionDesigner addQuestion={addQuestion} />
          </div>
        </div>
        <div className="w-1/2 p-6 overflow-y-auto" ref={previewRef}>
          <h3 className="text-xl font-semibold mb-4">Question Preview</h3>
          <SurveyPreview questions={questions} setQuestions={setQuestions} />
        </div>
      </div>
    </div>
  );
};

export default SurveyDesigner;