import React from 'react';
import { FaGripVertical, FaTrash } from 'react-icons/fa';

const QuestionDisplay = ({ question, index, onChange, isPreview, dragHandleProps, onDelete }) => {
  const renderAnswerInput = () => {
    switch (question.type) {
      case 'multiple_choice':
        return (
          <div>
            {question.options.map((option, optionIndex) => (
              <div key={optionIndex} className="mb-2">
                <input
                  type="radio"
                  name={`question-${index}`}
                  id={`question-${index}-option-${optionIndex}`}
                  className="mr-2"
                  onChange={() => !isPreview && onChange(option)}
                  disabled={isPreview}
                />
                <label htmlFor={`question-${index}-option-${optionIndex}`}>{option}</label>
              </div>
            ))}
          </div>
        );
      case 'checkbox_list':
        return (
          <div>
            {question.options.map((option, optionIndex) => (
              <div key={optionIndex} className="mb-2">
                <input
                  type="checkbox"
                  id={`question-${index}-option-${optionIndex}`}
                  className="mr-2"
                  onChange={(e) => !isPreview && onChange(option, e.target.checked)}
                  disabled={isPreview}
                />
                <label htmlFor={`question-${index}-option-${optionIndex}`}>{option}</label>
              </div>
            ))}
          </div>
        );
      case 'short_text':
        return (
          <input
            type="text"
            placeholder="Enter your answer"
            className="w-full rounded-md border-gray-300 border-2 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
            onChange={(e) => !isPreview && onChange(e.target.value)}
            disabled={isPreview}
          />
        );
       case 'numeric':
            return (
              <input
                type="number"
                placeholder="Enter a number"
                className="w-full rounded-md border-gray-300 border-2 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                onChange={(e) => !isPreview && onChange(e.target.value)}
                disabled={isPreview}
              />
            );        
      case 'long_text':
        return (
          <textarea
            placeholder="Enter your answer"
            rows="4"
            className="w-full rounded-md border-gray-300 border-2 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
            onChange={(e) => !isPreview && onChange(e.target.value)}
            disabled={isPreview}
          ></textarea>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`flex items-center ${isPreview ? 'mb-6 p-4 border rounded-md shadow-sm bg-white' : 'mb-6'}`}>
      {isPreview && (
        <div {...dragHandleProps} className="mr-2 cursor-move">
          <FaGripVertical />
        </div>
      )}
      <div className="flex-grow">
        <h4 className="text-lg font-semibold mb-2">
          {index + 1}. {question.text}
        </h4>
        {renderAnswerInput()}
      </div>
      {isPreview && (
        <FaTrash
          className="ml-2 cursor-pointer text-red-500"
          onClick={() => onDelete(index)}
        />
      )}
    </div>
  );
};

export default QuestionDisplay;
