import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Import uuid
import { PlusCircle, MinusCircle, Plus } from 'lucide-react';

const QuestionDesigner = ({ addQuestion }) => {
  const [questionType, setQuestionType] = useState('multiple_choice');
  const [questionText, setQuestionText] = useState('');
  const [options, setOptions] = useState(['']);

  const handleAddOption = () => {
    setOptions([...options, '']);
  };

  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newQuestion = {
      id: uuidv4(), // Assign a unique id
      type: questionType,
      text: questionText,
      options:
        questionType === 'multiple_choice' || questionType === 'checkbox_list' ? options : [],
    };
    addQuestion(newQuestion);
    setQuestionText('');
    setOptions(['']);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2">
        Question Type:
        <select 
          value={questionType} 
          onChange={(e) => setQuestionType(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 border-2 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
          >
            <option value="multiple_choice">Multiple Choice</option>
            <option value="checkbox_list">Checkbox List</option>
            <option value="short_text">Short Text</option>
            <option value="long_text">Long Text</option>
            <option value="numeric">Numeric</option>
            </select>
      </label>
    </div>
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2">
        Question Text:
        <input 
          type="text" 
          value={questionText} 
          onChange={(e) => setQuestionText(e.target.value)} 
          required
          className="mt-1 block w-full rounded-md border-gray-300 border-2 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
        />
      </label>
    </div>
    {(questionType === 'multiple_choice' || questionType === 'checkbox_list') && (
      <div className="mb-4">
        <h4 className="text-lg font-semibold mb-2">Options:</h4>
        {options.map((option, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={option}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              required
              className="flex-grow rounded-md border-gray-300 border-2 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50 mr-2"
            />
            <button 
              type="button" 
              onClick={() => handleRemoveOption(index)}
              className="bg-red-500 hover:bg-red-600 text-white p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 flex items-center justify-center"
            >
              <MinusCircle size={20} />
            </button>
          </div>
        ))}
          <button 
            type="button" 
            onClick={handleAddOption}
            className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <PlusCircle size={20} className="inline mr-2" />
            Add Option
          </button>
        </div>
      )}
      <button 
        type="submit"
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
      >
        <Plus size={20} className="inline mr-2" />
        Add Question
      </button>
    </form>
  );
};

export default QuestionDesigner;
