import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getSurveys, createSurvey } from '../utils/serverComm';

function SurveyList({ user }) {
  const [surveys, setSurveys] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.isAnonymous) {
      fetchSurveys();
    }
  }, [user]);

  const fetchSurveys = async () => {
    try {
      const data = await getSurveys();
      setSurveys(data.surveys);
    } catch (error) {
      console.error('Error fetching surveys:', error);
    }
  };

  const handleNewSurvey = async () => {
    try {
      const response = await createSurvey();
      console.log('Survey created successfully:', response);
      navigate(`/survey/${response.surveyId}`);
    } catch (error) {
      console.error('Error creating survey:', error);
    }
  };

  return (
    <div className="container mx-auto py-8">
      <div className="grid grid-cols-2 gap-4">
        {/* New Survey Box */}
        <div
          onClick={handleNewSurvey}
          className="cursor-pointer border-2 border-dashed border-gray-400 rounded-lg flex items-center justify-center p-8"
        >
          <span className="text-2xl font-bold">+ New Survey</span>
        </div>

        {/* Existing Surveys */}
        {surveys.map((survey) => (
          <Link
            key={survey._id}
            to={`/survey/${survey._id}`}
            className="border rounded-lg p-4 hover:shadow-md cursor-pointer"
          >
            <h3 className="text-xl font-bold">{survey.title || 'Untitled Survey'}</h3>
            <p className="text-gray-600">Questions: {survey.questions.length}</p>
            <p className="text-gray-600">Responses: {survey.responseCount}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SurveyList;