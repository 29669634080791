import React from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../utils/StrictModeDroppable';
import QuestionDisplay from './QuestionDisplay';

const SurveyPreview = ({ questions, setQuestions }) => {
  // Handler for drag end event
  const handleOnDragEnd = result => {
    if (!result.destination) return;

    const items = Array.from(questions);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    setQuestions(items);
  };

  // Handler for deleting a question
  const handleDeleteQuestion = index => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  return (
    <div>
      {questions.length === 0 && (
        <p className="text-gray-500 italic">No questions added yet.</p>
      )}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <StrictModeDroppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={`p-2 ${snapshot.isDraggingOver ? 'bg-blue-100' : 'bg-gray-100'}`}
              {...provided.droppableProps}
            >
              {questions.map((question, index) => (
                <Draggable key={question.id} draggableId={question.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={snapshot.isDragging ? 'bg-green-100' : ''}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <QuestionDisplay
                        question={question}
                        index={index}
                        isPreview={true}
                        dragHandleProps={provided.dragHandleProps}
                        onDelete={handleDeleteQuestion}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};

export default SurveyPreview;
