import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSurvey, submitSurveyResponse } from '../utils/serverComm';
import QuestionDisplay from './QuestionDisplay';

const SurveyViewer = () => {
  const [survey, setSurvey] = useState(null);
  const [answers, setAnswers] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { surveyId } = useParams();

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await getSurvey(surveyId, false); // Pass false to indicate no auth required
        setSurvey(response.survey);
        setHasSubmitted(response.hasSubmitted);
      } catch (error) {
        console.error('Error fetching survey:', error);
      }
    };

    fetchSurvey();
  }, [surveyId]);

  const handleAnswerChange = (questionId, answer) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: answer
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await submitSurveyResponse(surveyId, answers);
      if (response.message === 'You have already submitted a response for this survey.') {
        setHasSubmitted(true);
      } else {
        setHasSubmitted(true);
      }
    } catch (error) {
      console.error('Error submitting survey:', error);
      alert('Failed to submit survey. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!survey) {
    return <div>Loading survey...</div>;
  }

  if (hasSubmitted) {
    return (
      <div className="max-w-3xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-6">{survey.title}</h1>
        <p className="text-lg">Thank you for your response!</p>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-6">{survey.title}</h1>
      <form onSubmit={handleSubmit}>
        {survey.questions.map((question, index) => (
          <QuestionDisplay
            key={question.id}
            question={question}
            index={index}
            onChange={(answer) => handleAnswerChange(question.id, answer)}
            isPreview={false}
          />
        ))}
        <button
          type="submit"
          className="mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-blue-300"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Survey'}
        </button>
      </form>
    </div>
  );
};

export default SurveyViewer;